<template>
  <div class="contact">
    <div class="content">
      <div class="content-div">
        <div class="title">公司地址</div>
        <div class="address">四川省成都市武侯区高朋大道10号航利大厦A座401</div>
      </div>
      <div class="content-div">
        <div class="title">联系方式</div>
        <!-- <div class="item">
          <span>联系电话：</span>
          <span>00000000000</span>
        </div> -->
        <div class="item">
          <span>联系邮箱：</span>
          <span>guangfeishi@163.com</span>
        </div>
        <!-- <div class="item">
          <span>QQ：</span>
          <span>00000000000</span>
        </div> -->
      </div>
    </div>
    <div class="footer">@四川广飞视信息技术有限公司</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.contact {
  height: 100%;
  background: url("~@/assets/image/five.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 828px;
    height: 428px;
    background: rgba(36, 37, 38, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content-div {
      .title {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #efeeee;
        margin: 20px 0;
      }
      .address,
      .item {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #dedede;
        line-height: 1.5;
      }
    }
  }
  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 90px;
    // background: rgba(56, 56, 56, 0.7);
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #dedede;
    line-height: 90px;
  }
}
</style>